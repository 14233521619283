/*globals $*/
'use strict';

import '../../../library/datetime/date-time-picker.min.js';
const getValidationFunctions = () => import("./validate");

$( document ).ready(function() {

    $('#tapatapa__newsletter-form #tapatapa__newsletter-form_submit-button').on('click', function(e){
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateNewsletterForm();
        });
    });

    $('#tapatapa__booking-form #tapatapa__booking-form_submit-button').on('click', function(e){
        $('#booking_loader').show();
        e.preventDefault();
        setTimeout(function(){ 
            getValidationFunctions().then(validateModule => {
                validateModule.validateBookingForm();
            });
        }, 500);
        
    });


    $('#booking_date').dateTimePicker({
        format: 'dd/MM/yyyy'
    });

});

