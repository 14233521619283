/*globals $*/
'use strict';

import lozad from 'lozad'

const getMaps = () => import("./external/functions/maps");
const getSvg = () => import("./external/functions/svg");
const getOwlCarousel = () => import("./external/functions/owl_carousel");
const getLozad = () => import("./external/functions/lozad");

const getHeaderFunctions = () => import("./external/functions/header");

var didScroll;
var lastScrollTop = 0;
var delta = 5;
var navbarHeight = 40; //$('header').outerHeight();


/* DETECT MOBILE */

var isMobile = false; //initiate as false
// device detection
if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
    || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) { 
    isMobile = true;
}

$( document ).ready(function() {

    // COOKIES BUTTON
    $('#banner-calcotada').on('click', function() {
       $('#popup-calcotada').addClass('visible');
    });
    $('#close-popup-calcotada').on('click', function() {
       $('#popup-calcotada').removeClass('visible');
    });

    //popup biosphere
    $('#bt-popup-biosphere').on('click', function() {
       $('#popup-biosphere').addClass('visible');
    });
    $('#close-popup-biosphere').on('click', function() {
       $('#popup-biosphere').removeClass('visible');
    });
    
    // COOKIES BUTTON
    $('#removecookie').on('click', function() {
       $('#cookie-allow').css('display', 'none');
    });

    //LOAD OWL CAROUSEL IF NEEDED
    if( $('.owl-carousel-home').length > 0 ) {
        getOwlCarousel().then(owlModule => {
            owlModule.initOwlCarouselHome();
        });
    }
    //LOAD OWL CAROUSEL IF NEEDED
    if( $('.owl-carousel-banners').length > 0 ) {
        getOwlCarousel().then(owlModule => {
            owlModule.initOwlCarouselBanners();
        });
    }
    //LOAD OWL CAROUSEL IF NEEDED
    if( $('.owl-carousel-banners-top').length > 0 ) {
        getOwlCarousel().then(owlModule => {
            owlModule.initOwlCarouselBannersTop();
        });
    }
    //LOAD OWL CAROUSEL IF NEEDED
    if( $('.owl-carousel-tapatapa').length > 0 ) {
        getOwlCarousel().then(owlModule => {
            owlModule.initOwlCarouselTapatapa();
        });
    }
    //LOAD OWL CAROUSEL IF NEEDED
    if( $('.owl-carousel-map').length > 0 ) {
        getOwlCarousel().then(owlModule => {
            owlModule.initOwlCarouselMap();
        });
    }
    if( $('.owl-carousel-sameheight').length > 0 ) {
        getOwlCarousel().then(owlModule => {
            owlModule.initOwlCarouselSameHeight();
        });
    }
    // LOAD GOOGLE MAPS IF NEEDED
    $(".map__overlay").on('click', function(){ 
        $('.map__overlay-loader').show();
        getMaps().then(mapsModule => {
            mapsModule.initMap('barcelona__map');
        });

    });

    //LOAD SVG IF NEEDED
    if($(".svg").length > 0) {
        getSvg().then(svgModule => {
            svgModule.prepareSvg();
        });
    }
    //LOAD NAVBAR IF NEEDED
    if($("#nav-open-close").length > 0) {
        getHeaderFunctions().then(headerModule => {
            headerModule.showNavBar();
        });
    }

    $('.map__tabs-item').on('click', function(){
        if( !$(this).hasClass('map__tabs-item_disabled') ){
            $('.map__overlay-loader').show();
            $('.map__tabs-item').removeClass('map__tabs-item_active');
            $(this).addClass('map__tabs-item_active');

            var tab = $(this).attr('data-tab');
            getMaps().then(mapsModule => {
                mapsModule.initMap(tab);
            });

            if(tab == 'barcelona__map'){
                getMaps().then(mapsModule => {
                    mapsModule.fitMapBarcelona();
                });
            }else{
                getMaps().then(mapsModule => {
                    mapsModule.fitMapMadrid();
                });
            }
        }
    });

    $('#map__window_close').on('click', function(){
        getMaps().then(mapsModule => {
            mapsModule.closeInfowindow();
        });
    });

    $('#map__container_close').on('click', function(){
        $('#map__container_content').removeClass('map__container_full');
        $('#map__container_content').addClass('map__container_background');
        $('.map__overlay').show();
        $('#general__map').attr('data-map', 'false');
    });


    //intervalo de tiempo para calcular constantemente posicion del scroll
    setInterval(function() {
        if (didScroll) {
            hasScrolled();
            didScroll = false;
        }

    }, 250);

    
    $('.page-carta__item').each(function(){ calcularPosicion( $(this) ); });
    $('.page-reservations__item').each(function(){ calcularPosicion( $(this) ); });


    $('.contact_button').click(function() {
        $('html, body').animate({
            scrollTop: $("#map__container_content").offset().top
        }, 1500);
        getMaps().then(mapsModule => {
            mapsModule.initMap('barcelona__map');
        });
        $('.banner-contact').addClass('visible');
    });

    //VIDEO HOME
    var playing;
    if( $('#layer1').hasClass('playing') ){
        playing = "video1";
        //$('#btvideo1').hide();
        $('#btvideo1').addClass('invisible');
        //$('#btMute').show();
    } else {
        playing = "none";
        //$('#btMute').hide();
    }

    $('#btvideo1').click(function() {

        //if ($(window).width() > 800){
            //$('#video1').get(0).pause();
            if(playing == "video1"){
                $('#video1').get(0).pause();
                //$('#btvideo1').show();
                $('#btvideo1').removeClass('invisible');
                //$('#btMute').hide();
                playing = "none";
            } else {
                $('#video1').get(0).play();
                //$('#btvideo1').hide();
                $('#btvideo1').addClass('invisible');
                //$('#btMute').show();
                playing = "video1";
            }
        /*} else {
            $('#video1').get(0).pause();
            playing = "none";
            $('#btvideo1').hide();
        }*/
    });

});

$(window).scroll(function(){ 
    didScroll = true;

    $('.presentation_product').each(function(){ calcularPosicion( $(this) ); });
    $('.item-promo').each(function(){ calcularPosicion( $(this) ); });
    $('.page-carta__item').each(function(){ calcularPosicion( $(this) ); });
    $('.page-reservations__item').each(function(){ calcularPosicion( $(this) ); });

    $('.moveto-right').each(function(){ moveToRight( $(this) ); });
    $('.moveto-left').each(function(){ moveToLeft( $(this) ); });

});
$(window).resize(function(){
  

  //parar el video
    if($(window).width() < 800){
        $('#video1').get(0).pause();
        playing = "none";
        $('#btvideo1').hide();
    }
});

document.addEventListener('DOMContentLoaded', function(){
  if($(".lozad").length > 0) {
    getLozad().then(lozadModule => {
        lozadModule.lozadLoad();
    });
  }
});



function hasScrolled() {
    var st = $(window).scrollTop();
    // Make sure they scroll more than delta
    if(Math.abs(lastScrollTop - st) <= delta)
        return;

    // If they scrolled down and are past the navbar, add class .nav-up.
    // This is necessary so you never see what is "behind" the navbar.
    if (st > lastScrollTop && st > navbarHeight){
        // Scroll Down
        //$('header').removeClass('nav-down').addClass('nav-up');
        $('.header').addClass('fixed');
    } else {
        // Scroll Up
        if(st + $(window).height() < $(document).height()) {
            //$('header').removeClass('nav-up').addClass('nav-down');
            $('.header').removeClass('fixed');
        }
    }

    lastScrollTop = st;
}

function calcularPosicion(element) {
    var alto = $(window).height() - 10;
    var wScroll = $(window).scrollTop();

    if(element.length && (element.offset().top - alto) < wScroll ){
        element.addClass('show');
        //element.removeClass('notshow');
    }
}
function moveToRight(element) {
    var alto = $(window).height() / 3;
    var wScroll = $(window).scrollTop();
    var pos;

    if(element.length && (element.offset().top - alto) < wScroll ){
        pos = (wScroll - (element.offset().top - alto))/5;
    } else {
        pos = 0;
    }

    element.css({
     "-webkit-transform": "translateX(" + pos + "px)",
     "-moz-transform": "translateX(" + pos + "px)",
     "-ms-transform": "translateX(" + pos + "px)",
     "transform": "translateX(" + pos + "px)"
      });
}
function moveToLeft(element) {
    var alto2 = $(window).height() / 3;
    var wScroll2 = $(window).scrollTop();
    var pos2;

    if(element.length && (element.offset().top - alto2) < wScroll2 ){
        pos2 = (wScroll2 - (element.offset().top - alto2))/5;
    } else {
        pos2 = 0;
    }

    element.css({
     "-webkit-transform": "translateX(-" + pos2 + "px)",
     "-moz-transform": "translateX(-" + pos2 + "px)",
     "-ms-transform": "translateX(-" + pos2 + "px)",
     "transform": "translateX(-" + pos2 + "px)"
      });
}


